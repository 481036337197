import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
  Stack,
  Skeleton,
  Backdrop,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ArrowDown, ArrowUp, hamburguerDash, help } from 'assets/svgs';
import { Drawer, stylesAnimation, TGTooltip } from './styles';
import menuItemsProduct from 'data/navs/MenuItemsProduct';
import TGIcon from 'TGComponents/global/TGIcon';
import arrowBack from 'assets/svgs/productResumo/arrowBack.svg';
import MenuItemsEvent from 'data/navs/MenuItemsEvent';
import { EventContext } from '../../../providers/event-context';
import TGShortenText from 'TGComponents/global/TGShortenText';
import { useGetData } from 'hooks/getData';
import { AlertsContext } from 'providers/alerts-context';
import { setCookie } from 'utils/cookies';

import { ArrowTopRight } from 'TGComponents/icons';
import LoadingTag from 'assets/animations/loading_tag.gif';

const submenuItems = [
  {
    text: 'Ajuda e suporte',
    icon: help,
    to: 'https://ajuda.ticketandgo.com.br/pt-br/',
  },
];

// Novo item para acessar a área de membros
const memberAreaItem = {
  text: 'Acessar minha área de membros',
  to: '/area-de-membros',
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: ' 0 2rem',
  ...theme.mixins.toolbar,
}));

const SubMenu = ({ children, open }) => {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0',
          padding: '2px',
          px: 0.5,
        }}
      >
        {children?.map((subitem) => (
          <ListItem key={subitem.text} disablePadding>
            <ListItemButton
              className="submenu-items-desk"
              component={NavLink}
              to={subitem.to}
              activeClassName="active"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                pl: 5,
                position: 'relative',
                transition: 'padding-left 0.3s ease', // Add transition for padding
              }}
            >
              <ListItemText
                primary={subitem.text}
                sx={{
                  opacity: open ? 1 : 0,
                  marginLeft: '12px',
                  transition: 'opacity 0.3s ease',
                }} // Add transition for opacity
              />
              <span
                className="submenu-span"
                style={{
                  position: 'absolute',
                  left: '16px',
                  fontSize: '1rem',
                }}
              >
                •
              </span>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default function TGSideBar({ menuItems }) {
  const [searchParams] = useSearchParams();
  const slug = searchParams.get('slug');
  const uuid = searchParams.get('uuidProduto');
  const uuidEvento = searchParams.get('uuid');
  const menuTipo = searchParams.get('menuTipo');
  const navigate = useNavigate();
  const location = useLocation();
  const { eventName } = React.useContext(EventContext);
  const { addAlert } = React.useContext(AlertsContext);

  const { user } = useSelector((state) => ({
    user: state.user || {},
  }));

  const {
    data: dataMembersArea,
    fetchData,
    error: errorMembersArea,
  } = useGetData(
    'api/v2/member/start/only',
    'Error fetching data',
    {},
    false,
    true,
    false
  );

  const urlPathName = location.pathname;
  React.useEffect(() => {
    if (urlPathName === '/admin/eventos/credenciamento') {
      setTimeout(() => {
        setOpen(false);
      }, 300);
    }
  }, [location.pathname]);

  const [open, setOpen] = React.useState(true);
  const [subMenuOpen, setSubMenuOpen] = React.useState(() => {
    const initialSubMenuOpen = {};
    menuItems.forEach((item, index) => {
      if (item?.children) {
        initialSubMenuOpen[index] = false;
      }
    });
    return initialSubMenuOpen;
  });
  const [loadingBackdrop, setLoadingBackdrop] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSubMenuOpen({});
  };

  const handleSubMenuToggle = (index) => {
    setSubMenuOpen((prev) => {
      const updatedSubMenuOpen = { [index]: !prev[index] };

      // Fechar todos os outros submenus
      Object.keys(prev).forEach((key) => {
        if (key !== index && prev[key]) {
          updatedSubMenuOpen[key] = false;
        }
      });

      return updatedSubMenuOpen;
    });

    setOpen(true);
  };

  const renderMenuItem = (item, index) => (
    <React.Fragment key={item.text}>
      <ListItem disablePadding>
        <TGTooltip
          title={item.text}
          placement="right"
          enterTouchDelay={0}
          disableHoverListener={open}
        >
          <ListItemButton
            className="sidebar-menu"
            component={item?.children ? 'div' : NavLink} // Use 'div' como componente se houver children
            to={
              item?.children
                ? null
                : menuTipo === 'produto'
                  ? `${item?.to}?slug=${slug}&uuidProduto=${uuid}&menuTipo=produto`
                  : menuTipo === 'evento'
                    ? `${item?.to}?slug=${slug}&uuid=${uuidEvento}&menuTipo=evento`
                    : item?.to
            }
            onClick={item?.children ? () => handleSubMenuToggle(index) : null} // Manipulador apenas se houver children
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: open ? 1.5 : 2.5,
              '&:active': {
                backgroundColor: 'red',
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? '12px' : 'auto',
                justifyContent: 'center',
              }}
            >
              <img src={item?.icon} alt={item?.text} width="24" height="24" />
            </ListItemIcon>
            <ListItemText primary={item?.text} sx={{ opacity: open ? 1 : 0 }} />
            {item?.isBeta && open && (
              <Typography
                sx={{
                  background: 'linear-gradient(to right, #00B2FF, #00FF85)',
                }}
                borderRadius={'4px'}
                padding="1px 6px"
                fontSize={'10px'}
                color="#fafafa"
                marginRight={'4px'}
              >
                Beta
              </Typography>
            )}

            {item?.children && open && (
              <div>
                {subMenuOpen[index] ? (
                  <img src={ArrowUp} alt="seta-pra-cima" />
                ) : (
                  <img src={ArrowDown} alt="seta-pra-baixo" />
                )}
              </div>
            )}
          </ListItemButton>
        </TGTooltip>
      </ListItem>

      <SubMenu children={item?.children} open={subMenuOpen[index]} />
    </React.Fragment>
  );

  const handleDrawerClose2 = () => {
    setSubMenuOpen((prev) => {
      const updatedSubMenuOpen = { ...prev };

      const isAnySubMenuOpen = Object.values(prev).some((isOpen) => isOpen);

      Object.keys(updatedSubMenuOpen).forEach((key) => {
        updatedSubMenuOpen[key] = false;
      });

      if (isAnySubMenuOpen) {
        setTimeout(() => {
          setOpen(false);
        }, 400);
      } else {
        setOpen(false);
      }

      return updatedSubMenuOpen;
    });
  };

  const loadMembersArea = async () => {
    setLoadingBackdrop(true);
    await fetchData();
  };

  React.useEffect(() => {
    if (dataMembersArea) {
      const objectSave = {
        access_token: user?.access_token,
        uuid_produtora: user?.roles[0].uuid_produtora,
        produtora: user?.roles[0].produtora,
        perfil: user?.roles[0].perfil,
        user_email: user?.email,
      };

      setCookie(
        'user',
        JSON.stringify(objectSave),
        7,
        '.ticketandgo.com.br'
      );

      if (dataMembersArea?.data[0]?.url) {
        setCookie(
          'members_area',
          dataMembersArea?.data[0],
          7,
          '.ticketandgo.com.br'
        );

        window.open(
          // `http://localhost:3001/area-de-membros/${dataMembersArea.data[0].url}`,
          `https://ap-membros.ticketandgo.com.br/area-de-membros/${dataMembersArea.data[0].url}`,
          '_self'
        );
        return setLoadingBackdrop(false);
      }
      window.open(
        'https://ap-membros.ticketandgo.com.br/criacao-area-membros',
        '_self'
      );
      return setLoadingBackdrop(false);
    }
    if (errorMembersArea) {
      setLoadingBackdrop(false);
      addAlert({
        message: 'Erro ao acessar área de membros.',
        type: 'error',
        open: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        tgCustom: true,
      });
    }
  }, [dataMembersArea, errorMembersArea]);

  return (
    <>
      <Drawer
        variant="permanent"
        ModalProps={{
          keepMounted: true,
          position: 'fixed',
        }}
        open={open}
        sx={{
          zIndex: 999,
          display: { xs: 'none', lg: 'block' },
          padding: '28px',
          '.MuiDrawer-root .MuiListItemButton-root:hover': {
            backgroundColor: '#00b2ff',
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{
            maxHeight: `calc(100vh - 120px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#CCC',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
          }}
        >
          <DrawerHeader
            sx={{
              justifyContent: !open ? 'center' : '',
              paddingTop: '14px',
            }}
          >
            {/*Remove lógica até o workspace sair, só voltar menuTipo === 'evento'  */}
            {menuTipo === '123' ? (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  color="#222"
                  justifyContent={'space-between'}
                >
                  {open && (
                    <Typography
                      variant="title-m"
                      fontWeight={600}
                      width="100%"
                      style={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {eventName ? (
                        <TGShortenText
                          screensLengths={[10, 10, 10, 12, 30]}
                          text={eventName ? eventName : '--'}
                        />
                      ) : (
                        <Skeleton width="100px" />
                      )}
                    </Typography>
                  )}
                  <IconButton onClick={handleDrawerClose}>
                    <img src={hamburguerDash} alt="open-and-close-icon" />
                  </IconButton>
                </Box>
                <Divider mb="10px" sx={{ background: '#EEE' }} />
                {/*{open && (
                <Stack
                  width='100%'
                  onClick={() =>
                    navigate(
                      menuTipo === 'produto'
                        ? '/admin/produto'
                        : '/admin/eventos/index'
                    )
                  }
                  sx={{ cursor: 'pointer' }}
                  gap='12px'
                  direction='row'
                >
                  <TGIcon src={arrowBack} height='20px' width='20px' />
                  <Typography
                    color='neutral.20'
                    fontSize='16px'
                    fontWeight='400'
                  >
                    Voltar
                  </Typography>
                </Stack>
              )}*/}
              </>
            ) : (
              <>
                {open && (
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    color="#222"
                    justifyContent={'space-between'}
                  >
                    <Typography fontSize={'1rem'} fontWeight={600}>
                      Painel
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                      <img src={hamburguerDash} alt="open-and-close-icon" />
                    </IconButton>
                  </Box>
                )}
              </>
            )}

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: 'auto',
                ...(open && { display: 'none' }),
              }}
            >
              <img src={hamburguerDash} alt="open-and-close-icon" />
            </IconButton>
          </DrawerHeader>

          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginBottom: '32px',
              paddingInline: open ? '20px' : '8px',
            }}
          >
            <>
              {(menuTipo === 'produto'
                ? menuItemsProduct
                : menuTipo === 'evento'
                  ? MenuItemsEvent
                  : menuItems
              ).map((item, index) => renderMenuItem(item, index))}
            </>
          </List>

          {/* Novo item "Acessar minha área de membros" */}
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              paddingInline: open ? '20px' : '8px',
            }}
          >
            <ListItem key={memberAreaItem.text} disablePadding>
              <ListItemButton
                component={NavLink}
                onClick={() => loadMembersArea()}
                activeClassName="active"
                sx={stylesAnimation}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          fontSize: '0.75rem',
                          fontWeight: 400,
                          color: '#222',
                        }}
                      >
                        <span>Acessar minha</span>
                        <span>área de membros</span>
                      </Box>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />

                  <span>
                    <ArrowTopRight />
                  </span>
                </Box>
              </ListItemButton>
            </ListItem>
          </List>

          <Divider
            variant="middle"
            sx={{ backgroundColor: '##EEE', opacity: 0.1 }}
          />

          {/* Itens do submenu de ajuda e suporte */}
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              paddingInline: open ? '20px' : '8px',
            }}
          >
            {submenuItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton
                  component={NavLink}
                  target="_blank"
                  to={item.to}
                  activeClassName="active"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? '4px' : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={item.icon}
                      alt={item.text}
                      width="24"
                      height="24"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      <Backdrop
        sx={{
          backgroundColor: '#ffffff',
          color: '#000',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingBackdrop}
      >
        <img
          src={LoadingTag}
          alt="Carregando"
          style={{ width: '80px', height: '80px' }}
        />
      </Backdrop>
    </>
  );
}
