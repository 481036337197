function setCookie(name, value, days, domain = '') {
  let expires = '';
  if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Dias em milissegundos
      expires = '; expires=' + date.toUTCString();
  }
  
  // Define as opções adicionais de segurança e compatibilidade entre domínios
  const sameSite = 'SameSite=None'; // Necessário para compartilhamento entre domínios
  const secure = 'Secure'; // Requerido para SameSite=None em HTTPS
  const domainPart = domain ? `; domain=${domain}` : ''; // Configura o domínio, se fornecido
  
  document.cookie = `${name}=${value || ''}${expires}; path=/${domainPart}; ${sameSite}; ${secure}`;
}

export { setCookie };